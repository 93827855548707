<i18n>
ru:
  promo: "Промокод"
ua:
  promo: "Промокод"
us:
  promo: "Promo code"
</i18n>

<template>
  <div
    v-if="!!clientStore.ClientState?.data"
    class="v-w-100 v-mb-xxs v-d-flex v-justify-content-between v-cart-promo-code"
    :class="{
      disabled: totalAmountWithoutRemoved === 0
    }"
  >
    <input
      :id="uid"
      class="v-cart-promo-code__input"
      :class="{
        'v-cart-promo-code__input--active':
          clientStore.ClientState.data.Promo?.IsPromoApplied,
        'v-cart-promo-code__input--error': promoError
      }"
      :disabled="promoApplied"
      :aria-label="translate('promoCode.promo')"
      enterkeyhint="next"
      :placeholder="translate('promoCode.promo')"
      v-model="promoInput"
      @keyup.enter="apply"
    />
    <ui-button
      :disabled="promoInput.length === 0"
      :click-event="apply"
      :label="translate('sidebar.cart')"
      color="primary"
      type="fill"
      square
      :size="44"
    >
      <ui-icon
        v-if="promoApplied"
        :size="24"
        color="white"
        disable-hover
        name="close"
      />
      <ui-icon
        v-else
        :size="24"
        color="white"
        disable-hover
        name="arrowRightAlt"
      />
    </ui-button>
  </div>

  <TransitionGroup
    name="list"
    tag="ul"
    class="v-cart-promo-code__errors"
  >
    <li
      v-for="message in clientStore.ClientState?.data?.Promo?.ErrorMessages ?? []"
      :key="message"
      class="v-d-flex v-align-items-center"
    >
      <ui-icon
        class-name="v-mr-xxs"
        color="error"
        :size="16"
        name="error"
        disable-hover
      />
      <span
        class="v-error-color v-typography-text-6"
        v-html="sanitize(message)"
      />
    </li>
  </TransitionGroup>
</template>

<script setup lang="ts">
const clientStore = useClientStore()

const { sanitize, translate } = useI18nSanitized()
const { callForGiftsPopup, totalAmountWithoutRemoved } = useCart()

const promoInput = ref<string>('')

const uid = useId()

onMounted(() => {
  if (import.meta.client)
    clientStore.initClientState().then(() => {
      promoInput.value = sanitize(
        clientStore.ClientState?.data?.Promo?.PromoCode ?? ''
      )
    })
})

async function apply(): Promise<void> {
  if (promoApplied.value) {
    if (clientStore.ClientState.data?.Promo?.IsPromoApplied) {
      await clientStore.clearPromoCode()
    }
    promoApplied.value = false
    promoInput.value = ''
    document.querySelector<HTMLInputElement>(`#${uid}`)?.focus()
  } else {
    await clientStore.applyPromoCode(promoInput.value, false)
  }
}

const promoApplied = ref<boolean>(
  clientStore.ClientState.data?.Promo?.IsPromoApplied ?? false
)

const promoError = computed<boolean>(() => {
  if (!clientStore.ClientState?.data?.Promo) return false

  return (
    clientStore.ClientState.data.Promo.ErrorMessages.length > 0 &&
    clientStore.ClientState.data.Promo.ErrorMessages[0] !== '' &&
    !clientStore.ClientState.data.Promo.IsPromoApplied
  )
})
const multipleGiftsInCart = computed<boolean>(() => {
  return clientStore.ClientState?.data?.Cart?.MultipleGiftsInCart ?? false
})

watch(
  () => clientStore.ClientState?.data?.Cart?.MultipleGiftsAvailable ?? false,
  (newValue: boolean, oldValue: boolean) => {
    if (newValue && !oldValue && !multipleGiftsInCart.value) {
      callForGiftsPopup()
    }
  }
)

watch(
  () => clientStore.ClientState?.data?.Promo?.IsPromoApplied,
  (newValue) => (promoApplied.value = newValue ?? false)
)
watch(
  () => clientStore.ClientState.data?.Promo?.PromoCode,
  (newContent, oldContent) => {
    if (newContent !== oldContent) {
      promoInput.value = newContent ?? ''
    }
  }
)
</script>

<style lang="scss">
@use '~/assets/variables';

.v-cart-promo-code {
  &__input {
    background: variables.$White;
    appearance: none;
    resize: none;
    width: 100%;
    border: 0;
    font-size: 1rem;

    &:disabled {
      opacity: 1;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }

  &__errors {
    padding: 0;
    margin: 0;
    gap: 0.5rem;
  }
}
</style>
