<i18n>
ru:
  cart: Корзина
ua:
  cart: Кошик
us:
  cart: Cart
</i18n>

<template>
  <header-parts-basket-button-page @click="callForSidebar" />

  <ClientOnly>
    <Teleport to="#__nuxt">
      <AnimatePresence>
        <motion.div
          v-if="sidebarOpen"
          :key="`${uid}-backdrop`"
          class="v-slide-menu-backdrop"
          @click="callForSidebar"
          :initial="{ opacity: 0 }"
          :animate="{ opacity: 1 }"
          :exit="{ opacity: 0 }"
        />
      </AnimatePresence>
      <AnimatePresence>
        <motion.div
          v-if="sidebarOpen"
          :key="`${uid}-cart`"
          class="v-sidebar"
          data-test-id="slide-small-basket"
          :initial="{ right: -500 }"
          :animate="{ right: 0 }"
          :exit="{ right: -500 }"
          :transition="{
            duration: 0.4
          }"
        >
          <cart-rhudaur-sidebar-content :close-function="callForSidebar" />
        </motion.div>
      </AnimatePresence>
    </Teleport>
  </ClientOnly>
</template>

<script setup lang="ts">
import { motion } from 'motion-v'

const restaurantStore = useRestaurantStore()

const router = useRouter()
const { eventOn } = useEmitter()
const uid = useId()

const sidebarOpen = ref<boolean>(false)

router.beforeEach(() => (sidebarOpen.value ? callForSidebar() : undefined))

async function callForSidebar(): Promise<void> {
  if (sidebarOpen.value) {
    document.body.classList.remove('v-open-overflow--manual')
    sidebarOpen.value = false
  } else {
    sidebarOpen.value = true
    document.body.classList.add('v-open-overflow--manual')
  }
}

const { stop } = watch(
  () => sidebarOpen.value,
  (newValue) => {
    if (newValue) {
      restaurantStore.Metrics?.sendMetricCartView(true)
      stop()
    }
  }
)

onMounted(() => {
  eventOn('v-returned-back-from-cart', () => {
    callForSidebar()
  })
})
</script>

<style lang="scss">
@use '~/assets/mixins';
@use '~/assets/variables';

.v-sidebar {
  background: variables.$Background;
  color: variables.$Mono1000;
  position: fixed;
  z-index: 1060;
  top: 0;
  height: 100vh;
  box-shadow: variables.$FloatShadow;

  width: 500px;

  @include mixins.lg {
    width: 400px;
  }

  @include mixins.md {
    width: 370px;
  }

  @include mixins.sm {
    width: 100vw;
  }
}
</style>
